<template>
<v-app>
    <div class="frame-popup">
        <header>
            <h1>비상접수 수동저장</h1>
            <div class="is-right">
                <v-btn class="jh-btn is-icon" title="닫기" @click="close"><i class="jh-icon-close-lg is-white"></i></v-btn>
            </div>
        </header>
        <main>
            <div class="jh-form-wrap">                
                <table class="jh-tbl-detail">
                    <colgroup>
                        <col width="120px">
                        <col>
                        <col width="120px">
                        <col>
                    </colgroup>
                    <tbody>
                        <tr>
                            <th><label>고객명</label></th>
                            <td><input type="text" class="jh-form"></td>
                            <th><label class="is-required">주민번호</label></th>
                            <td><input type="text" class="jh-form"></td>
                        </tr>
                        <tr>
                            <th><label>발신번호</label></th>
                            <td><input type="text" class="jh-form"></td>
                            <th><label>ISSUE</label></th>
                            <td><input type="text" class="jh-form"></td>
                        </tr>
                        <tr>
                            <th><label>인입경로</label></th>
                            <td><input type="text" class="jh-form"></td>
                            <th><label>상담일</label></th>
                            <td><input type="text" class="jh-form"></td>
                        </tr>
                        <tr>
                            <th><label>발신번호</label></th>
                            <td><input type="text" class="jh-form"></td>
                            <th><label>ISSUE</label></th>
                            <td><input type="text" class="jh-form"></td>
                        </tr>
                        <tr>
                            <th><label>상담유형</label></th>
                            <td colspan="3">                     
                                <div class="jh-cols">
                                    <v-select class="jh-form" :items="items"></v-select>
                                    <v-select class="jh-form" :items="items"></v-select>
                                    <v-select class="jh-form is-col-fix" style="width: 188px;" :items="items"></v-select>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th><label>상담제목</label></th>
                            <td colspan="3"><input type="text" class="jh-form"></td>
                        </tr>
                        <tr>
                            <th><label>상담내용</label></th>
                            <td colspan="3"><textarea class="jh-form" style="height: 120px;"></textarea></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </main>
        <footer>
            <v-btn class="jh-btn is-md" @click="">초기화</v-btn>
            <v-btn class="jh-btn is-md is-main" @click="">저장</v-btn>
        </footer>
    </div>
</v-app>
</template>
    
<script>
import { mixin } from "@/mixin/mixin.js";

export default {
    name: "MENU_T101201", //name은 'MENU_' + 파일명 조합
    mixins: [mixin],
    props: {
    },
    components: {
    },
    data() {
        return {
        };
    },
    
    created() {
    },
    
    methods: {
        close(){
        self.close();
        }  
    },
    mounted(){
    },
    computed: {   
    },
    watch: {
    }
};
</script>

<style></style>